.gradient-background {
  display: flex;
  background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 24%,
          rgba(214, 250, 240, 1) 46%,
          rgba(147, 227, 224, 1) 77%,
          rgba(165, 201, 217, 1) 100%
  );
  overflow: hidden;
  position: relative;
  min-height: 100vh;

  @keyframes bgAnim {
    0% {
      background-position: 0 30%;
    }
    33% {
      background-position: 70% 10%;
    }
    66% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 0 30%;
    }
  }
}

.form-container {
  min-height: 400px;
  width: 650px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 32px
}

